<template>
    
    <div class="h-full w-full flex flex-col">
        <div class="h-10 flex flex-row justify-between items-center px-2 flex-none">
            <div class="flex flex-row">
                <span class="text-aux font-semibold text-lg">Chargeability</span>
            </div>
        </div>
        <transition
            name="fade"
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
            mode="out-in">
            <router-view @setSL="setSL" @options="options" :options="activeOptions" :sl="sl" />
        </transition>
    </div>

</template>

<script>

export default {
    data() {
        return {
            sl: null,
            activeOptions: []
        }
    },
    methods: {
        goto(path) { this.$router.push( { name:path }) },
        setSL(sl) {
            this.sl = sl
        },
        options(options) { this.activeOptions = options }
    }
}
</script>